const protectedRoutes = [
  '/checkout/event-info', // TODO: Faizan - host-cart-v0 - index, and request-callback-success exist;
  '/checkout/user-info',
  '/my-events/[userEventId]/event-cart-details',
  '/my-orders',
  '/my-orders/[orderId]/order-details',
  '/my-orders/[orderId]/details',
  '/payment-success/[orderId]',
  '/planner-carts/[cartId]',
  '/planner-carts/[cartId]/ordered-cart-view',
  '/user-profile',
  '/user-profile/address',
  '/user-profile/get-help',
  '/user-profile/my-events',
  '/user-profile/personal-details'
];

const isProtectedRoute = (route) => protectedRoutes.includes(route);

export default isProtectedRoute;
