// TODO: Refactor the module to reduce duplicates.
// TODO: Use async-await instead of promises.

import axios from 'axios';

import { HOST_PORTAL_FQDN } from '@/services/connections.service';
import { getAuthToken } from '@/services/identity.service';

axios.defaults.headers = {
  'content-type': 'application/json',
  'x-origin-domain': HOST_PORTAL_FQDN
};

export const getWithoutAuth = (url) =>
  new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });

export const postWithoutAuth = (url, entity) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, entity)
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });

export const deleteWithoutAuth = (url, entity) =>
  new Promise((resolve, reject) => {
    axios
      .delete(url, {
        data: entity
      })
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });

const getOptionsForWithAuth = () => {
  const token = getAuthToken();
  const headers = {
    'x-access-token': token
  };
  return { headers, withCredentials: true };
};

export const getWithAuth = (url) => {
  const withAuthOptions = getOptionsForWithAuth();
  return new Promise((resolve, reject) => {
    axios
      .get(url, withAuthOptions)
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const postWithAuth = (url, entity) => {
  const withAuthOptions = getOptionsForWithAuth();
  return new Promise((resolve, reject) => {
    axios
      .post(url, entity, withAuthOptions)
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
