import _ from 'lodash';
import Cookies from 'universal-cookie';

import { ROOT_DOMAIN } from '@/services/connections.service';

const COOKIE_NAME_PREFIX = `H_${String(
  process.env.NEXT_PUBLIC_APP_ENV
).toUpperCase()}_`;

export const COOKIE_MAP = {
  GA: '_ga',
  GCL: '_gcl_aw',
  HOST_PORTAL_USER_IDENTITY: `${COOKIE_NAME_PREFIX}HOST_PORTAL_USER_IDENTITY`,
  OPS_PORTAL_USER_IDENTITY: `${COOKIE_NAME_PREFIX}OPS_PORTAL_USER_IDENTITY`,
  SELECTED_CART: `${COOKIE_NAME_PREFIX}SELECTED_CART`,
  SELECTED_USER_EVENT: `${COOKIE_NAME_PREFIX}SELECTED_USER_EVENT`
};

const isDevelopment = process.env.NEXT_PUBLIC_APP_ENV === 'development';
const defaultCookieOptions = {
  domain: `.${ROOT_DOMAIN}`,
  doNotParse: false,
  maxAge: 1000 * 3600 * 24 * 30,
  path: '/',
  secure: !isDevelopment
};

const cookies = new Cookies(null, defaultCookieOptions);

export const getCookie = ({ name }) => cookies.get(name, defaultCookieOptions);

export const removeCookie = ({ name }) =>
  cookies.remove(name, defaultCookieOptions);

export const setCookie = ({ name, data }) => {
  if (_.isEmpty(data)) {
    removeCookie({ name });
  } else {
    cookies.set(name, data, defaultCookieOptions);
  }
  return getCookie({ name });
};
