import { CART_STATUS } from '@/config/common';
import * as HttpService from '@/services/http.service';

import {
  COOKIE_MAP,
  getCookie,
  removeCookie,
  setCookie
} from '@/services/cookie.service';
import {
  ADD_ITEM_TO_CART,
  ADD_ITEM_TO_DEFAULT_CART,
  AUTHENTICATE_CART,
  GET_CART_DETAILS,
  GET_USER_EVENT_CARTS
} from '@/services/url.service';

export const addItemToDefaultCart = (userCartId, body) =>
  HttpService.postWithoutAuth(ADD_ITEM_TO_DEFAULT_CART(userCartId), body);

export const getUserEventCarts = (userCartId, userEventId) =>
  HttpService.getWithAuth(GET_USER_EVENT_CARTS(userCartId, userEventId));

export const authenticateCart = (userId, guestUserId) =>
  HttpService.postWithoutAuth(AUTHENTICATE_CART, { userId, guestUserId });

export const addItemToCart = (userCartId, body, cartId) =>
  HttpService.postWithoutAuth(ADD_ITEM_TO_CART({ userCartId, cartId }), body);

export const getCartDetails = ({ cartId, userCartId }) =>
  HttpService.getWithAuth(GET_CART_DETAILS({ cartId, userCartId }));

export const getUserEventIdFromSelectedCartCookie = (cartCookie) =>
  cartCookie?.userEventId || cartCookie?.userEventSummary?.id;

export const getSelectedCart = () =>
  getCookie({
    name: COOKIE_MAP.SELECTED_CART
  });

export const setSelectedCart = ({
  data,
  resetUserEventForDefaultCart = false
}) => {
  const currentSelectedUserEvent = getCookie({
    name: COOKIE_MAP.SELECTED_USER_EVENT
  });
  const currentSelectedCart = getCookie({ name: COOKIE_MAP.SELECTED_CART });
  const isRequestedCartIsDefaultCart = data?.isDefault;
  if (isRequestedCartIsDefaultCart) {
    const updateCartCookie = setCookie({
      name: COOKIE_MAP.SELECTED_CART,
      data
    });
    if (resetUserEventForDefaultCart) {
      return {
        cartCookie: updateCartCookie,
        eventCookie: removeCookie({
          name: COOKIE_MAP.SELECTED_USER_EVENT
        })
      };
    }
    return {
      cartCookie: updateCartCookie,
      eventCookie: currentSelectedUserEvent
    };
  }

  const isUserEventSame =
    getUserEventIdFromSelectedCartCookie(currentSelectedCart) ===
    getUserEventIdFromSelectedCartCookie(data);
  const cartCookie = setCookie({
    name: COOKIE_MAP.SELECTED_CART,
    data
  });

  if (!isUserEventSame) {
    return {
      cartCookie,
      eventCookie: setCookie({
        name: COOKIE_MAP.SELECTED_USER_EVENT,
        data: getUserEventIdFromSelectedCartCookie(data)
      })
    };
  }

  return { cartCookie, eventCookie: currentSelectedUserEvent };
};

export const isCartEditable = ({ status }) => {
  const editableCartStatuses = [CART_STATUS.DRAFT, CART_STATUS.COLLABORATE];
  return editableCartStatuses.includes(status);
};
