// TODO: Pass environment variables through config before depending upon them.
import { HOST_API_BASE_URL } from '@/services/connections.service';

const interpolatePath = ({ pathname, pathParams }) =>
  Object.entries(pathParams).reduce(
    (template, [key, value]) => template.replace(`:${key}`, value ?? ''),
    pathname
  );

const buildQueryString = ({ queryParams }) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return queryString ? `?${queryString}` : '';
};

const getURLPath = ({ pathname, pathParams = {}, queryParams = {} }) => {
  const interpolatedPath = interpolatePath({ pathname, pathParams });
  const queryString = buildQueryString({ queryParams });
  return `${interpolatedPath}${queryString}`;
};

const getAPIURL = ({
  baseUrl,
  pathname,
  pathParams = {},
  queryParams = {}
}) => {
  const path = getURLPath({ pathname, pathParams, queryParams });
  const url = new URL(`${baseUrl}${path}`);
  return url.toString();
};

const getHostAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: HOST_API_BASE_URL, pathname, pathParams });

export const CONTACT_US = getHostAPIURL('/contact-us');

export const CONTACT_US_USER = getHostAPIURL('/user-contact-us');

export const LEAD_CAPTURE = getHostAPIURL('/leads');

export const GET_USER_EVENT_CARTS = (userCartId, userEventId) =>
  getHostAPIURL('/users/:userCartId/carts/list?userEventId=:userEventId', {
    userCartId,
    userEventId
  });

export const GET_CART_DETAILS = ({ cartId, userCartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/details', {
    cartId,
    userCartId
  });

export const CART_ITEM_OPERATION_URL = ({ userCartId, cartId, cartItemId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/cart-item/:cartItemId', {
    userCartId,
    cartId,
    cartItemId
  });

export const ADD_ITEM_TO_DEFAULT_CART = (userCartId) =>
  getHostAPIURL('/users/:userCartId/default-cart/cart-item', { userCartId });

export const ADD_ITEM_TO_CART = ({ userCartId, cartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/cart-item', {
    cartId,
    userCartId
  });

export const AUTHENTICATE_CART = getHostAPIURL('/authenticate-cart');

export const GET_PRODUCTS = (
  skip,
  limit,
  parentCategory,
  category,
  subCategory,
  sortOrder
) =>
  getHostAPIURL(
    '/products?skip=:skip&limit=:limit&parentCategory=:parentCategory&category=:category&subCategory=:subCategory&sortOrder=:sortOrder',
    {
      skip,
      limit,
      parentCategory,
      category,
      subCategory,
      sortOrder
    }
  );

export const GET_PRODUCT = (slug) => getHostAPIURL('/products/:slug', { slug });

export const GET_PRODUCT_BY_ID = (productId) =>
  getHostAPIURL('/products/:productId/id', { productId });

export const GET_PRODUCT_BREADCRUMBS = (slug) =>
  getHostAPIURL('/products/:slug/breadcrumbs', { slug });

export const CREATE_GUEST_USER = getHostAPIURL('/guest-user');

export const CREATE_USER = getHostAPIURL('/users');

export const USER_LOGIN = getHostAPIURL('/sessions');

export const RECENT_OTP = getHostAPIURL('/sessions/resend-otp');

export const GET_USER = (userId) => getHostAPIURL('/users/:userId', { userId });

export const GET_CATEGORY_TREE = getHostAPIURL('/category/tree');

export const GET_CATEGORY_SEO_DETAILS = (categorySlug) =>
  getHostAPIURL('/categories/:categorySlug/seo-details', { categorySlug });

export const GET_USER_ONGOING_ORDERS = (userId, skip, limit) =>
  getHostAPIURL('/users/:userId/orders?type=ONGOING&skip=:skip&limit=:limit', {
    userId,
    skip,
    limit
  });

export const GET_USER_COMPLETED_ORDERS = (userId, skip, limit) =>
  getHostAPIURL(
    '/users/:userId/orders?type=COMPLETED&skip=:skip&limit=:limit',
    {
      userId,
      skip,
      limit
    }
  );

export const GET_USER_ORDER_DETAILS = (userId, orderId) =>
  getHostAPIURL('/users/:userId/orders/:orderId', { userId, orderId });

export const POST_CHECKOUT_EVENT_PRIORITY_INQUIRY = getHostAPIURL(
  '/inquiries/priority-event-inquiry'
);

export const GET_CHECKOUT_EVENT_LOOKUP = getHostAPIURL('/checkout/eventType');

export const EVENT_CART_CHECKOUT = ({ userCartId, cartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/checkout-non-default-cart', {
    userCartId,
    cartId
  });

export const GET_CITY_DETAILS = getHostAPIURL('/cities');

export const GET_SEARCH_SUGGESTIONS = (text) =>
  getHostAPIURL('/search-suggestions?text=:text', { text });

export const APPLY_PROMO_CODE = getHostAPIURL('/apply-promo-code');

export const REMOVE_PROMO_CODE = getHostAPIURL('/remove-promo-code');

export const UPDATE_USER_DETAILS = (userId) =>
  getHostAPIURL('/users/:userId/user-details', { userId });

export const UPDATE_USER_ADDRESS = (userId) =>
  getHostAPIURL('/users/:userId/address', { userId });

export const POST_RFQ_QUOTE_DETAILS = getHostAPIURL('/rfq-submission-details');

export const UPDATE_USER_TIMEZONE = (userId) =>
  getHostAPIURL('/users/:userId/user-time-zone', { userId });

export const GET_PUBLISHED_COLLECTIONS = (skip, limit) =>
  getHostAPIURL('/published-collections?skip=:skip&limit=:limit', {
    skip,
    limit
  });

export const GET_COLLECTION_BY_SLUG = (slug) =>
  getHostAPIURL('/collections/:slug', { slug });

export const POST_VENDOR_REGISTRATION_INQUIRY = getHostAPIURL(
  '/inquiries/vendor-registration'
);

export const GET_HAFLA_BLOGS = getHostAPIURL('/blogs');

export const GET_PRICE_RANGE_BY_SLUG = getHostAPIURL('/product-pricerange/');

export const GET_CART_QUOTATION = ({ userCartId, cartId }) =>
  getHostAPIURL('/user/:userCartId/carts/:cartId/quote-pdf', {
    userCartId,
    cartId
  });

export const GET_CARTS_BY_EVENT_ID = ({ userCartId, userEventId }) =>
  getHostAPIURL('/users/:userCartId/user-events/:userEventId/carts', {
    userCartId,
    userEventId
  });

export const GET_USER_EVENT_DETAILS_BY_ID = ({ userId, userEventId }) =>
  getHostAPIURL('/users/:userId/user-events/:userEventId/details', {
    userId,
    userEventId
  });

export const GET_USER_ONGOING_EVENTS = (userId, skip, limit) =>
  getHostAPIURL(
    '/users/:userId/user-events?type=ONGOING&skip=:skip&limit=:limit',
    {
      userId,
      skip,
      limit
    }
  );

export const GET_USER_PAST_EVENTS = (userId, skip, limit) =>
  getHostAPIURL(
    '/users/:userId/user-events?type=PAST&skip=:skip&limit=:limit',
    {
      userId,
      skip,
      limit
    }
  );

export const DEFAULT_CART_CHECKOUT = ({ userCartId, cartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/checkout-default-cart', {
    cartId,
    userCartId
  });

export const GET_CART_CHECKOUT_INFO = ({ cartId, userCartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/checkout', {
    cartId,
    userCartId
  });

export const CHECKOUT_CART_AND_PAY = ({ cartId, userCartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/checkout', {
    cartId,
    userCartId
  });

export const GET_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_INFO = ({
  orderId,
  userId
}) =>
  getHostAPIURL('/users/:userId/orders/:orderId/anybody-pay-due', {
    orderId,
    userId
  });

export const POST_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_URL_REQUEST = ({
  orderId,
  userId
}) =>
  getHostAPIURL('/users/:userId/orders/:orderId/anybody-pay-due', {
    orderId,
    userId
  });

export const GET_PAYMENT_STATUS = ({ orderId, paymentId, userCartId }) =>
  getHostAPIURL('/users/:userCartId/orders/:orderId/payments/:paymentId', {
    orderId,
    paymentId,
    userCartId
  });

export const GET_READ_ONLY_CART_FOR_PUBLIC = ({ cartId }) =>
  getHostAPIURL('/carts/:cartId/read-only-details', {
    cartId
  });
