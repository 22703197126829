import { useEffect, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import { DEVICE_SURFACE } from '@/config/common';
import tailwindConfig from '@/tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

let { sm, md, lg, xl } = fullConfig.theme.screens;
sm = +sm.substring(0, sm.length - 2);
md = +md.substring(0, md.length - 2); // for improvement
lg = +lg.substring(0, lg.length - 2); // for improvement
xl = +xl.substring(0, xl.length - 2); // for improvement

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState();
  const isMobileFunction = () => {
    const { innerWidth: width, innerHeight: height } = window;
    if (width < sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    function handleResize() {
      isMobileFunction();
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [isMobile, setIsMobile];
};

export const GetUiDeviceSurface = () => {
  const [isMobile] = useIsMobile();
  return isMobile ? DEVICE_SURFACE.MOBILE : DEVICE_SURFACE.WEB;
};
