import { isAuthenticated } from 'services/identity.service';
import isProtectedRoute from 'services/security.service';

const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ router, children }) => {
  if (isBrowser()) {
    if (!isAuthenticated() && isProtectedRoute(router.pathname)) {
      router.replace({
        pathname: '/login', // TODO: Hardik/Faizan identify and replace all such static paths by out path dictionary
        query: { redirectTo: router.asPath }
      });
    }
  }
  return children;
};

export default WithAuth;
