import { staticMediaStoreBaseURL } from 'config/common';

const config = {
  description: 'AI based Event ideation, planning & booking platform',
  openGraph: {
    images: [
      {
        alt: 'Hafla logo',
        height: 630,
        url: `${staticMediaStoreBaseURL}/icons/hafla-icon.png`,
        width: 1200
      }
    ],
    locale: 'en_US',
    site_name: 'Hafla: AI based Event ideation, planning & booking platform',
    type: 'website',
    url: 'https://www.hafla.com/'
  },
  title: 'Hafla',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@haflaOfficial',
    site: '@haflaOfficial'
  }
};

export default config;
