import _ from 'lodash';

import {
  CART_ORDER_SOURCE,
  IMAGEKIT_DAM_ROOT_DIRECTORY,
  MEDIA_SOURCE,
  STATIC_MEDIA_STORE_PATH_STUB,
  mediaStoreBaseURL,
  staticMediaStoreBaseURL
} from '@/config/common';

const imageKitMediaURL = process.env.NEXT_PUBLIC_MEDIA_STORE_IMAGE_KIT_ORIGIN
  ? `${process.env.NEXT_PUBLIC_MEDIA_STORE_IMAGE_KIT_ORIGIN}${IMAGEKIT_DAM_ROOT_DIRECTORY}`
  : STATIC_MEDIA_STORE_PATH_STUB;

const localHostMediaURL = '';

const removeLeadingBackSlash = (str = '') => str.replace(/^\//, '');

const getGalleryImageTitle = ({ eventName, organiser } = {}) =>
  `${eventName}${organiser ? ` by ${organiser}` : ''}`;

const sortArrayOfObjectByKey = (array, key) =>
  array.sort((a, b) => (a[`${key}`] > b[`${key}`] ? 1 : -1));

export const itemImageDefault = {
  id: 1,
  imageTitle: 'default-image',
  original: `${staticMediaStoreBaseURL}/default-images/default-product.png`,
  sortOrder: 1,
  source: MEDIA_SOURCE.AWS_S3,
  thumbnail: `${staticMediaStoreBaseURL}/default-images/default-product.png`,
  url: `${staticMediaStoreBaseURL}/default-images/default-product.png`
};

export const getRankedImages = (images) => {
  const hasRank = images.length === images.every(({ rank }) => rank);
  if (hasRank) {
    return sortArrayOfObjectByKey(images, 'rank');
  }

  const hasSortOrder =
    images.length === images.every(({ sortOrder }) => sortOrder);
  if (hasSortOrder) {
    sortArrayOfObjectByKey(images, 'sortOrder').map((image, index) => ({
      ...image,
      rank: index + 1
    }));
  }

  return images.map((image, index) => ({
    ...image,
    source: image.source || MEDIA_SOURCE.AWS_S3,
    rank: index + 1
  }));
};

const patchOriginForStorybook = (origin) =>
  origin || STATIC_MEDIA_STORE_PATH_STUB;

export const getImageURL = ({ source, url: urlPath }) => {
  let origin = '';
  switch (source) {
    case MEDIA_SOURCE.CODE_REPO:
      origin = staticMediaStoreBaseURL;
      break;
    case MEDIA_SOURCE.IMAGE_KIT:
      origin = imageKitMediaURL;
      break;
    case MEDIA_SOURCE.LOCALHOST:
      origin = localHostMediaURL;
      break;
    case MEDIA_SOURCE.AWS_S3:
    default:
      origin = mediaStoreBaseURL;
      break;
  }
  origin = patchOriginForStorybook(origin);
  return urlPath ? `${origin}/${removeLeadingBackSlash(urlPath)}` : '';
};

export const transformImagesForGridAndCarousel = (images = []) => {
  const hasNumericIdAsIndex = images.every(({ id }) => id && !Number.isNaN(id));
  return images.map((imageRaw, index) => {
    const image = imageRaw || {};
    const sortOrder = hasNumericIdAsIndex ? image.id + 1 : index + 1;
    return {
      ..._.pick(image, [
        'eventGalleryDesktopThumbnail',
        'eventGalleryMobileThumbnail',
        'eventName',
        'id',
        'imageUrl',
        'organiser'
      ]),
      imageTitle: getGalleryImageTitle(image),
      sortOrder,
      url: removeLeadingBackSlash(image.imageUrl),
      source: MEDIA_SOURCE.CODE_REPO
    };
  });
};

const transformMediaListWithDetails = ({ entityType, items, source }) =>
  items
    .map(({ id: itemMediaId, media }, index) => ({
      ...media,
      [`${
        source === CART_ORDER_SOURCE.CART
          ? 'cartItemMediaId'
          : 'orderItemMediaId'
      }`]: itemMediaId,
      entityType,
      sortOrder: index + 1
    }))
    .sort((a, b) => a.sortOrder - b.sortOrder);

export const itemMediaListMapper = ({
  additionalMediaList: additionalMediaListRaw,
  source = CART_ORDER_SOURCE.CART,
  ...item
}) => {
  const { product, showProductMedia } = item;

  const productMedia = product?.productMedia || [];

  const hideProductMedia = !showProductMedia;

  const additionalMediaListForCarousel = transformMediaListWithDetails({
    entityType: source === CART_ORDER_SOURCE.CART ? 'cartItem' : 'orderItem',
    items: additionalMediaListRaw || [],
    source
  });

  const baseSortOrderToAccommodateHoles = additionalMediaListForCarousel.length;

  const productMediaForCarousel = transformMediaListWithDetails({
    entityType: 'product',
    items: hideProductMedia ? [] : productMedia,
    source
  }).map(({ sortOrder, ...image }) => ({
    ...image,
    sortOrder: sortOrder + baseSortOrderToAccommodateHoles
  }));

  const itemMediaForCarousel = [
    ...additionalMediaListForCarousel,
    ...productMediaForCarousel
  ];

  return {
    ...item,
    itemMediaForCarousel:
      itemMediaForCarousel.length === 0
        ? [itemImageDefault]
        : itemMediaForCarousel
  };
};
