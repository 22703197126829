import { getUser } from 'services/user.service';

// TODO: in-apt definition, also this should be in user.service
export const getUserDetails = async (userId) => {
  if (!userId) return false;
  const response = await getUser(userId);
  if (response.status) {
    return response.entity;
  }
  return false;
};

export default getUserDetails;
